import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/vee'
import './plugins/vuetoastification'
// import configs from '@/configs'

import { getPermissions, filters, calendar, errorFunction } from '@/utils'
Vue.filter('dateFilter', filters.dateFilter)
Vue.filter('floatFilter', filters.floatFilter)
Vue.filter('cpfCnpjFilter', filters.cpfCnpjFilter)
Vue.prototype.$calendar = calendar
Vue.prototype.$fnError = errorFunction

Vue.config.productionTip = false

Vue.prototype.$getPermissions = getPermissions

Vue.prototype.$ipUser = `https://fan.api.user.znaptech.com/`
Vue.prototype.$ipClient = `https://fan.api.client.znaptech.com/`
Vue.prototype.$ipSecurity = `https://fan.api.security.znaptech.com/`
Vue.prototype.$ipSales = `https://fan.api.sales.area.znaptech.com/`
Vue.prototype.$ipSalesPlanning = `https://fan.api.sales.planning.znaptech.com/`
Vue.prototype.$ipDynamicReport = `https://fan.api.dynamic.report.znaptech.com/`
Vue.prototype.$ipAccount = `https://fan.api.account.znaptech.com/`
Vue.prototype.$ipEvent = `https://fan.api.event.znaptech.com/`
Vue.prototype.$ipApprovalFlow = `https://fan.api.approval.flow.znaptech.com/`
Vue.prototype.$ipOrganization = `https://fan.api.organization.znaptech.com/`
Vue.prototype.$ipUnit = `https://fan.api.unit.znaptech.com/`
Vue.prototype.$ipProduct = `https://fan.api.product.znaptech.com/`
Vue.prototype.$ipComment = `https://fan.api.comment.znaptech.com/`
Vue.prototype.$ipCustomer = `https://fan.api.customer.znaptech.com/`
Vue.prototype.$ipOpexPlanning = `https://fan.api.opex.planning.znaptech.com/`
Vue.prototype.$ipCapexPlanning = `https://fan.api.capex.planning.znaptech.com/`
Vue.prototype.$ipVendor = `https://fan.api.vendor.znaptech.com/`
Vue.prototype.$ipPlPlanning = `https://fan.api.pl.planning.znaptech.com/`
Vue.prototype.$ipMessage = `https://fan.api.message.znaptech.com/`

Vue.prototype.$vuetify = vuetify

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
