const clients = [
    {
        id: 3,
        system: 'FAN',
        module: 'ZNAP_CAPEX_PLANNING',
        modulePath: 'capex', // 'capex' || 'homol.capex'
        language: 'pt',
        port: 8087,
        theme: 'fan.js',
        env: 'prod' // prod || homol
    }
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(3)

export default {
    ...configs
}
